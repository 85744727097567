.certificate-link {
  text-decoration: none;
  height: 100%;
  cursor: default;
}
.certificate-card-wrapper {
  height: 100%;
  transition: 0.2s;
}
.certificate-card {
  position: relative;
  background-color: #3b4353;
  border-radius: 5px;
  align-items: center;
  min-height: 158px;
  transition: 0.2s;
  height: 100%;
  cursor: pointer;
}
.certificate-card-wrapper:hover .certificate-card {
  transform: translateY(-10px);
  background-color: #474f63;
}
.certificate-card-top {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.certificate-card-top > h2 {
  position: absolute;
  top: 30px;
  text-align: center;
  font-weight: 700;
  margin: 0 30px;
  font-size: 22px;
  color: white;
  text-decoration: none;
  z-index: 1;
}
.image-wrapper {
  position: absolute;
  bottom: -50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
}
.certificate-card-bottom {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: calc(100% - 150px);
  color: white;
  padding: 65px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.certificate-card-bottom h2 {
  margin: 0;
}
.certificate-card-bottom h3 {
  margin: 20px 0;
  font-size: 18px;
}
.certificate-card-bottom ul {
  list-style-type: disc;
  text-align: left;
}
.certificate-card-bottom li {
  font-size: 16px;
}
.certificate-card-tech {
  margin-top: 20px;
}
.certificate-card-tech > ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: -7px;
  margin-left: -7px;
}
.certificate-card-tech > ul > li {
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
  margin-left: 7px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: rgb(94, 106, 128);
}
.certificate-bg {
  position: absolute;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
}
.logo-bg {
  border-radius: 50%;
}
.company-logo {
  max-width: 150px;
  z-index: 1;
  pointer-events: none;
}
@media screen and (max-width: 599px) {
  .certificate-card-wrapper:hover .certificate-card {
    transform: translateY(0);
    background-color: #3b4353;
  }
  .certificate-card-bottom {
    padding: 65px 15px 20px 15px;
  }
}
